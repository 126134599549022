<!-- eslint-disable no-unused-vars -->
<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <Alert />
    <div class="d-flex">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Demande de rôle AGE</h1>
    </div>

    <div class="text-left">
      <a
        @click="goToGdr()"
        class="fr-link p-0 color-pr fs-875 go-gdr-link mb-4"
        title="Lien la gestion des rôles href=/ms/gdr"
        tabindex="0"
        ><i class="ri-arrow-left-line"></i>Retour à Mes Entreprises / Mes
        Rôles</a
      >
      <div class="mt-3">
        <p>
          Veuillez sélectionner si vous êtes dirigeant de l'entreprise
          <template v-if="roleData"> [{{ roleData.rs }}] </template>
        </p>
      </div>
      <SelectionDirigeantAge
        v-if="roleData"
        :roleData="roleData"
        choixSelection="tous"
      ></SelectionDirigeantAge>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import SelectionDirigeantAge from '../components/SelectionDirigeantAge';
import Alert from '../shared/Alert';
export default {
  name: 'RoleAGE',
  mixins: [SkipLinkMixin],
  components: {
    SelectionDirigeantAge,
    Alert
  },
  data() {
    return {
      etape1: '',
      proconnectInfo:
        'Une vérification automatique de votre statut sera effectuée grâce aux bases de données dirigeants de ProConnect.',
      nonDirigeant: '',
      nonDirigeantError: false,
      adresse: '',
      roleData: undefined
    };
  },
  mounted() {
    if (
      this.getRouteData &&
      this.getRouteData.roleData &&
      this.getRouteData.roleData.siren &&
      this.getRouteData.roleData.siren.length
    ) {
      this.roleData = this.getRouteData.roleData;
    } else {
      console.error('Aucun siren defini');
      this.goToGdr();
    }
  },
  methods: {
    goToGdr() {
      this.$router.push({ name: 'Gestion des rôles' });
    },
    ...mapActions(['showPopup', 'setContent'])
  },
  computed: {
    ...mapGetters(['getRouteData'])
  }
};
</script>

<style lang="scss" scoped>
.go-gdr-link {
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

.go-gdr-link:hover {
  text-decoration: underline 1.5px !important;
}
a {
  color: black;
  background-image: none;
}

@media only screen and (max-width: 760px) {
  .title {
    font-size: 2rem;
  }
}
</style>
